.bg-grey {
  background-color: #eeeeee;
}

.ReactVirtualized__Table__row.row:hover {
  background-color: rgba(29, 67, 84, 0.2);
  cursor: pointer;
}

.ReactVirtualized__Table__row.row.bg-grey--dark {
  background-color: #bbbbbb;
  cursor: pointer;
}

.cell-measurer {
  margin: 2px;
  word-break: break-word;
}

.ReactVirtualized__Table__headerRow {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px 4px 0 0;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
}
